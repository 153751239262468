/*! modernizr 3.6.0 (Custom Build) | MIT *
 * https://modernizr.com/download/?-webp-mq-setclasses !*/
!(function(e, n, t) {
  function o(e, n) {
    return typeof e === n;
  }

  function A(e) {
    var n = u.className,
      t = Modernizr._config.classPrefix || '';
    if ((d && (n = n.baseVal), Modernizr._config.enableJSClass)) {
      var o = new RegExp('(^|\\s)' + t + 'no-js(\\s|$)');
      n = n.replace(o, '$1' + t + 'js$2');
    }
    Modernizr._config.enableClasses &&
      ((n += ' ' + t + e.join(' ' + t)),
      d ? (u.className.baseVal = n) : (u.className = n));
  }

  function a() {
    var e, n, t, A, a, i, s;
    for (var r in c)
      if (c.hasOwnProperty(r)) {
        if (
          ((e = []),
          (n = c[r]),
          n.name &&
            (e.push(n.name.toLowerCase()),
            n.options && n.options.aliases && n.options.aliases.length))
        )
          for (t = 0; t < n.options.aliases.length; t++)
            e.push(n.options.aliases[t].toLowerCase());
        for (A = o(n.fn, 'function') ? n.fn() : n.fn, a = 0; a < e.length; a++)
          (i = e[a]),
            (s = i.split('.')),
            1 === s.length
              ? (Modernizr[s[0]] = A)
              : (!Modernizr[s[0]] ||
                  Modernizr[s[0]] instanceof Boolean ||
                  (Modernizr[s[0]] = new Boolean(Modernizr[s[0]])),
                (Modernizr[s[0]][s[1]] = A)),
            f.push((A ? '' : 'no-') + s.join('-'));
      }
  }

  function i() {
    return 'function' != typeof n.createElement
      ? n.createElement(arguments[0])
      : d
      ? n.createElementNS.call(n, 'http://www.w3.org/2000/svg', arguments[0])
      : n.createElement.apply(n, arguments);
  }

  function s() {
    var e = n.body;
    return e || ((e = i(d ? 'svg' : 'body')), (e.fake = !0)), e;
  }

  function r(e, t, o, A) {
    var a,
      r,
      l,
      f,
      d = 'modernizr',
      c = i('div'),
      p = s();
    if (parseInt(o, 10))
      for (; o--; )
        (l = i('div')), (l.id = A ? A[o] : d + (o + 1)), c.appendChild(l);
    return (
      (a = i('style')),
      (a.type = 'text/css'),
      (a.id = 's' + d),
      (p.fake ? p : c).appendChild(a),
      p.appendChild(c),
      a.styleSheet
        ? (a.styleSheet.cssText = e)
        : a.appendChild(n.createTextNode(e)),
      (c.id = d),
      p.fake &&
        ((p.style.background = ''),
        (p.style.overflow = 'hidden'),
        (f = u.style.overflow),
        (u.style.overflow = 'hidden'),
        u.appendChild(p)),
      (r = t(c, e)),
      p.fake
        ? (p.parentNode.removeChild(p), (u.style.overflow = f), u.offsetHeight)
        : c.parentNode.removeChild(c),
      !!r
    );
  }

  function l(e, n) {
    if ('object' == typeof e) for (var t in e) m(e, t) && l(t, e[t]);
    else {
      e = e.toLowerCase();
      var o = e.split('.'),
        a = Modernizr[o[0]];
      if ((2 == o.length && (a = a[o[1]]), 'undefined' != typeof a))
        return Modernizr;
      (n = 'function' == typeof n ? n() : n),
        1 == o.length
          ? (Modernizr[o[0]] = n)
          : (!Modernizr[o[0]] ||
              Modernizr[o[0]] instanceof Boolean ||
              (Modernizr[o[0]] = new Boolean(Modernizr[o[0]])),
            (Modernizr[o[0]][o[1]] = n)),
        A([(n && 0 != n ? '' : 'no-') + o.join('-')]),
        Modernizr._trigger(e, n);
    }
    return Modernizr;
  }

  var f = [],
    u = n.documentElement,
    d = 'svg' === u.nodeName.toLowerCase(),
    c = [],
    p = {
      _version: '3.6.0',
      _config: {
        classPrefix: '',
        enableClasses: !0,
        enableJSClass: !0,
        usePrefixes: !0,
      },
      _q: [],
      on: function(e, n) {
        var t = this;
        setTimeout(function() {
          n(t[e]);
        }, 0);
      },
      addTest: function(e, n, t) {
        c.push({ name: e, fn: n, options: t });
      },
      addAsyncTest: function(e) {
        c.push({ name: null, fn: e });
      },
    },
    Modernizr = function() {};
  (Modernizr.prototype = p), (Modernizr = new Modernizr());
  var h = (function() {
    var n = e.matchMedia || e.msMatchMedia;
    return n
      ? function(e) {
          var t = n(e);
          return (t && t.matches) || !1;
        }
      : function(n) {
          var t = !1;
          return (
            r(
              '@media ' + n + ' { #modernizr { position: absolute; } }',
              function(n) {
                t =
                  'absolute' ==
                  (e.getComputedStyle
                    ? e.getComputedStyle(n, null)
                    : n.currentStyle
                  ).position;
              }
            ),
            t
          );
        };
  })();
  p.mq = h;
  var m;
  !(function() {
    var e = {}.hasOwnProperty;
    m =
      o(e, 'undefined') || o(e.call, 'undefined')
        ? function(e, n) {
            return n in e && o(e.constructor.prototype[n], 'undefined');
          }
        : function(n, t) {
            return e.call(n, t);
          };
  })(),
    (p._l = {}),
    (p.on = function(e, n) {
      this._l[e] || (this._l[e] = []),
        this._l[e].push(n),
        Modernizr.hasOwnProperty(e) &&
          setTimeout(function() {
            Modernizr._trigger(e, Modernizr[e]);
          }, 0);
    }),
    (p._trigger = function(e, n) {
      if (this._l[e]) {
        var t = this._l[e];
        setTimeout(function() {
          var e, o;
          for (e = 0; e < t.length; e++) (o = t[e])(n);
        }, 0),
          delete this._l[e];
      }
    }),
    Modernizr._q.push(function() {
      p.addTest = l;
    }),
    Modernizr.addAsyncTest(function() {
      function e(e, n, t) {
        function o(n) {
          var o = n && 'load' === n.type ? 1 == A.width : !1,
            a = 'webp' === e;
          l(e, a && o ? new Boolean(o) : o), t && t(n);
        }

        var A = new Image();
        (A.onerror = o), (A.onload = o), (A.src = n);
      }

      var n = [
          {
            uri:
              'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=',
            name: 'webp',
          },
          {
            uri:
              'data:image/webp;base64,UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAABBxAR/Q9ERP8DAABWUDggGAAAADABAJ0BKgEAAQADADQlpAADcAD++/1QAA==',
            name: 'webp.alpha',
          },
          {
            uri:
              'data:image/webp;base64,UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA',
            name: 'webp.animation',
          },
          {
            uri:
              'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=',
            name: 'webp.lossless',
          },
        ],
        t = n.shift();
      e(t.name, t.uri, function(t) {
        if (t && 'load' === t.type)
          for (var o = 0; o < n.length; o++) e(n[o].name, n[o].uri);
      });
    }),
    a(),
    A(f),
    delete p.addTest,
    delete p.addAsyncTest;
  for (var g = 0; g < Modernizr._q.length; g++) Modernizr._q[g]();
  e.Modernizr = Modernizr;
})(window, document);
